<template>
    <div id="app" class="clearfix">
        <TopBar :route="$route.name"></TopBar>
        <Header :route="$route.name"></Header>
        <Slider :route="$route.name"></Slider>
        <router-view/>
        <Footer></Footer>
    </div>
</template>

<script>
    //import  '../assets/js/jquery.js'
   // import  '../assets/js/plugins.min.js'
    //import  '../assets/js/functions.js'
    import TopBar from './TopBar.vue'
    import Header from './Header.vue'
    import Footer from './Footer.vue'
    import Slider from './Slider.vue'
    export default {
        name: "ERV",
        components: {
            TopBar,
            Header,
            Footer,
            Slider
        },
        mounted(){
            console.log(this.$route.name)
        }
    }
</script>
<style>
    @media (min-width: 992px) {
        .sticky-header #header-wrap {
            top: 45px !important;
        }
    }
   @media (max-width: 991.98px) {
       .sticky-header #header-wrap {
           top: 91px !important;
       }
   }
</style>
